import React from 'react'
import Logo from '../Assets/UIPS.png';
import {IoLogoInstagram} from "react-icons/io";
import { SlSocialLinkedin } from 'react-icons/sl';
import { BsYoutube } from 'react-icons/bs';
import { SlLocationPin } from 'react-icons/sl';
import { BsTelephone } from 'react-icons/bs';
import { FaxRounded } from '@mui/icons-material';
import { SiMaildotru } from 'react-icons/si';
import {VscAccount} from 'react-icons/vsc'

const Footer = () => {
    return (
        <div className='footer-wrapper'>
            <div className='footer-section-one'>
                <div className='footer-logo-container'>
                    <img src={Logo} alt="" />
                </div>
                <div className='footer-icons'>
                    <IoLogoInstagram />
                    <SlSocialLinkedin />
                    <BsYoutube />
                </div>
            </div>
            <div className='footer-section-two'>
                <div className='footer-section-columns'>
                    <span><b>Contact Us</b></span>
                    <span><SlLocationPin/> P.O Box. 60817, Muhaisnah 4, Dubai, UAE </span>
                    <span><BsTelephone /> Tel No. 04-2543888</span>
                    <span><FaxRounded /> Fax No.: 04-2543889/04-2646850</span>
                    <span><SiMaildotru /> E-mail: uips-info@uips.ae</span>
                </div>
                <div className='footer-section-columns'>
                    <span><b>Quick Links</b></span>
                    <span><VscAccount/> Student Portal</span>
                    <span><VscAccount/> Parent Portal</span>
                    <span><VscAccount/> Payment Facility</span>
                </div>
            </div>
            

        </div>
    )
}

export default Footer;