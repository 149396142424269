import './App.css';
import Home from './Components/Home';
import Welcome from './Components/Welcome';
import Services from './Components/Services';
import About from './Components/About';
import InstaFeeds from './Components/InstaFeeds/InstaFeeds';
import Accredited from './Components/Accredited';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App">
      <Home/>
      <Welcome />
      <Services />
      <About/>
      <InstaFeeds token={'IGQVJYM3NpS3lXT2dzMVFhSngzWnpKLWtveVg0RlByalVpTUMzSElNUkVCaVVNVXdHVWgyLTNuWFNvM0NkRnFCbEd4eVNfamJBbUo5RjNYNTByVk9rejhWV1NkcGlsb0lxUkk4NTE3S1FrTExqNnQ4RwZDZD'} limit={6}/>
      <Accredited />
      <Footer />
    </div>
  );
}

export default App;
