import React from 'react'
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image.png";
import { BsFillPlayCircleFill } from 'react-icons/bs';

const Welcome = () => {
    return (
        <div className='about-section-container'>
            <div className='about-background-image-container'>
                <img src={AboutBackground} alt="" />
            </div>
            <div className='about-section-image-container'>
                <img src={AboutBackgroundImage} alt="" />
            </div>
            <div className='about-section-container-text'>
                <p className='primary-heading'>Welcome to UIPS!</p>   
                <p className='primary-text'>
                    United International Private School (UIPS) is the first Philippine School in Dubai, UAE. The school was founded in September 1992 by Col. Mohammad Al Hersh and Dr. Mohamed ALNeyadi, the owners who share both great vision on providing quality education and meeting demands of the Philippine community in Dubai and the neighboring emirates.
                </p>
                <div className='about-buttons-container'>
                    <button className='secondary-button'> Learn More</button>
                    <button className='watch-video-button'> <BsFillPlayCircleFill/> Gallery</button>
                </div>
            </div>


        </div>
    )
}

export default Welcome;
