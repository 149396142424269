import React from 'react'

const Feed = (props) => {
    const { id, caption, media_type, media_url} = props.feed
    let post;
    let cap;

    const charCount = caption.length;
    const truncated_cap = caption.slice(0, 40);

    //const handleClick = 'https://www.instagram.com/uipsdubai';
    const handleClick = media_url;

    switch(true) {
        case (charCount > 15):
            cap= (
                <p>
                    {truncated_cap} ... <a href={handleClick}><strong>See More</strong></a>
                </p>
            )
            break;
        default:
            cap= (
                <p>
                    {caption} 
                </p>
            )
    };


    switch (media_type) {
        case "VIDEO":
            post = (
                <div >
                    <video
                        width='100%'
                        height='auto' 
                        src={media_url} 
                        type="video/mp4" 
                        controls playsinline> {caption}
                    </video>
                    <p>{cap}</p>
                </div> 
            )

            break;
        case "CAROUSEL_ALBUM":
            post = (
                <div>
                    <img 
                        width='100%'
                        height='auto'
                        id={id} 
                        src={media_url} 
                        alt={caption} 
                    />
                    <p>{cap}</p>
                </div>
            );
            break;
        default:
            post = (
                <div>
                    <img 
                        width='100%'
                        height='auto'
                        id={id} 
                        src={media_url} 
                        alt={caption} 
                    />
                    <p>{cap}</p>
                </div>
            )

    }       

    return (
        <React.Fragment>
            {post}
        </React.Fragment>
    );
}

export default Feed;