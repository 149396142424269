import React from 'react'
import PhilosophyBackground from "../Assets/UIPS.png";

const About = () => {

    return (
        <div className='about-philosophy-container'>
            <div class='about-philosophyImage'>
                <img src={PhilosophyBackground} alt="" />
            </div> 
            <div className='about-philosophy-section'>
                <h1 className='primary-subheading'>Philosophy of Education</h1>
                <p>Education is a process of growth by which a person learns, thinks and acts rationally and effectively. The opportunity to develop and master basic skills attitudes and desired values must be made available to each other learner according to his own pace and ability. The respect for race, culture, religion, physical stature and social status of people from different countries must be developed by each learner. The home, the community and the school are the most important institutions wherein the body, the mind and the character of the youth could be fully nutured.</p>
            </div>
            <div className='about-missionvision-section'>
                <div className='mission-section'>
                    <h1 className='primary-subheading'>Mission</h1>
                    
                    <p>We are a dynamic educational institution that subscribes to a holistic development of mind and body through , Academic Excellence , Quality Performance , Respect for Human Values and Principles and Fervent Love of God, Country, Family and Fellow.</p>
                    

                </div>
                <div className='vision-section'>
                    <h1 className='primary-subheading'>Vision</h1>
                    <p>At UIPS, we aim to inspire our students to be holistic, life-long learners, where they are prepared to adapt and succeed anywhere in a rapidly changing world. As a school community, we endeavor to create strong partnerships with parents and form links with wider community to enhance the children's full development.</p>
                </div>
                
            </div>

        </div>
    )
}

export default About;