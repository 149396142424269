import React from 'react'
import Deped from '../Assets/logo1.png';
import Dubai from '../Assets/logo2.png';
import Emirates from '../Assets/logo3.jpg';
import unesco from '../Assets/logo4.png';
import unescoui from '../Assets/logo5.jpg';
import yearly from '../Assets/logo6.png';


const Accredited = () => {

  const AccreditedInfo = [
    {
        image : Deped
    },
    {
        image : Dubai
        
    },
    {
        image : Emirates
    },
    {
        image : unesco
    },
    {
        image : unescoui
    },
    {
      image : yearly
    }  ]


  return (
    <div className='Accredited-section-container'>
        {
            AccreditedInfo.map((data) => (
                  <div className='accredited-image-container'>
                      <img src={data.image} alt="" />
                  </div>  
                )
            )
        }
    </div>
  )
}

export default Accredited;