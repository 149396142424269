import React from 'react';
import { useState } from 'react';
import Logo from "../Assets/header1.png";
import { BsCart2 } from "react-icons/bs";
import { HiOutlineBars3 } from "react-icons/hi2";
import { SlArrowDown } from "react-icons/sl" ;


import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home"; 
import InfoIcon from "@mui/icons-material/Info"; 
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded"; 
import SchoolIcon from '@mui/icons-material/School';
import PeopleIcon from '@mui/icons-material/People';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const arrowStyles = {paddingTop: '8px'};

const Navbar = () => {
    
    const [openMenu, setOpenMenu] = useState(false);
    const menuOptions = [
        {
            text:"Home",
            icon:<HomeIcon/>
        },
        {
            text:"Admission",
            icon:<InfoIcon/>
        },
        {
            text:"Academics",
            icon:<SchoolIcon    />
        },
        {
            text:"UIPSians",
            icon:<PeopleIcon/>,
        },
        {
            text:"Connect",
            icon:<PhoneRoundedIcon/>,
        },
        {
            text:"Reserve Now",
            icon:<ArrowUpwardIcon/>,
        },
    ];

    
    
    return (
        <nav>
            <div className='nav-logo-container'>
                <img src={Logo} alt="" width="300" height="400"/>
            </div>

            <div className='navbar-links-container'>
                <a href="">Admission <SlArrowDown style={arrowStyles}/></a> 
                <a href="">Academics <SlArrowDown style={arrowStyles}/></a>
                <a href="" style={{color: '#FFF'}}>UIPSians <SlArrowDown style={arrowStyles}/></a>
                <a href="" style={{color: '#FFF'}}>Connect <SlArrowDown style={arrowStyles}/></a>
                <button className='reservenow-button'>Reserve Now</button>
            </div>
            <div className='navbar-menu-container'>
                <HiOutlineBars3 onClick={() =>setOpenMenu(true)} />
            </div>
            <Drawer open={openMenu} onclose={() => setOpenMenu(false)} anchor="right">
                <Box sx={{ width: 250 }}
                role="presentation"
                onClick={()=> setOpenMenu(false)}
                onKeyDown={()=> setOpenMenu(false)}
                >
                    <List>
                        {menuOptions.map((item) => (
                            <ListItem key={item.text} disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>


                </Box>

            </Drawer>

        </nav>
    )
}

export default Navbar