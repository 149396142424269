import React from 'react'
import Early from "../Assets/early.png";
import Primary from "../Assets/primary.png";
import Secondary from "../Assets/secondary.png";

const services = () => {

    const servicesInfoData = [
        {
            image : Early,
            title : "Early Childhood",
            text : "Aligned with the DepEd K to 12 basic education curriculum, the UIPS prepares the children in their kindergarten years to be academically ready while honing their skills and talents.", 
        },
        {
            image : Primary,
            title : "Primary",
            text : "The UIPS provides enhanced teaching strategies and improved learning styles based on the needs of the students in their primary years in school.", 
        },
        {
            image : Secondary,
            title : "Secondary",
            text : "Composed of the Junior High School and Senior High School, the UIPS delivers an enriched education solution that will make the students be globally competitive, socially responsible, and environmentally aware individuals.", 
        },    ]



    return (
        <div className='work-section-wrapper'>
            <div className='work-section-top'>
                <p className='primary-subheading'>Supports being Offered</p>
            </div>
            <div className='work-section-bottom'>
                {
                    servicesInfoData.map((data) => (
                        <div className='work-section-info'>
                            <div className='info-boxes-img-container'>
                                <img src={data.image} alt="" />
                            </div>
                            <h2>{data.title}</h2>
                            <p>{data.text}</p>
                        </div>
                    

                        )
                    
                    
                    )
                }
            </div>



        </div>
    )
}

export default services;