import React from 'react'
import Navbar from './Navbar'
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/5836.png";
import { FiArrowRight } from "react-icons/fi";

const Home = () => {
    return (
        <div className="home-container">
            <Navbar/>
            <div className='home-banner-container'>
                <div className='home-bannerImage-container'>
                    <img src={BannerBackground} alt="" />
                </div>

                <div className="home-text-section">
                    <h1 className='primary-heading'>
                        The First Filipino School and Your Child's Second Home in Dubai.
                    </h1>
                    <p className='primary-text'>
                        Reservation for Academic <b style={{color: "maroon"}}>2023-2024</b> is now Open. Admission is Open for 
                        <b style={{color: "maroon"}}> KG1 to Grade 12</b>. For SHS we offer <b style={{color: "maroon"}}>STEM, ABM, and HUMSS </b>.
                    </p>
                    <button className='secondary-button'>Reserve Now <FiArrowRight/></button>
                </div>
                <div className='home-image-container'>
                    <img src={BannerImage} alt="" /> 
                </div>

            </div>
        </div>
    )
}

export default Home