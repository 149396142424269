import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import InstaDP from './Assets/UIPS.png'
import Feed from './Feed'
import './instaFeeds.css'
import {IoLogoInstagram} from "react-icons/io";

const InstaFeeds = ({token, ...props}) => {
    const [feeds, setFeedsData] = useState([]);
    //use useRef to store the latest value of the prop without firing the effect
    const tokenProp = useRef(token);
    tokenProp.current = token;

    useEffect(() => {
        // this is to avoid memory leaks
        const abortController = new AbortController();

        async function fetchInstagramPost () {
        try{
            axios
                .get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=${props.limit}&access_token=${tokenProp.current}`)
                .then((resp) => {
                    setFeedsData(resp.data.data)
                })
        } catch (err) {
            console.log('error', err)
        }
        }

        // manually call the fecth function 
        fetchInstagramPost();

        return () => {
            // cancel pending fetch request on component unmount
            abortController.abort(); 
        };
    }, [props.limit])


    const handleClick = () => {
        window.location.href = 'https://www.instagram.com/uipsdubai';
    }

    return (

        <div className="insta-feed-section">
            <div className='insta-heading'>
                <h1 className='primary-subheading'>Social Media</h1>
            </div>
            <div className='insta-profile-container'>
                <div className='insta-profileImage'>
                    <img src={InstaDP} alt="UIPS Logo"></img>
                </div>
                <div className='insta-profileUsername'>
                    <h1>UIPS Dubai</h1>
                    <p>United International Private School</p>
                </div>
                <div className='insta-followButton-div'>
                    <button onClick={handleClick} target={"_blank"} className='insta-followButton' type='button'> <IoLogoInstagram></IoLogoInstagram>Follow us </button>
                </div>
            </div>
            


            <div className="container">
                {feeds.map((feed) => (
                    <Feed key={feed.id} feed={feed}  />
                ))}
            </div>
        </div>
    );
}

export default InstaFeeds;